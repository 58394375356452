export const DATE_FORMATS = {
  DAY: 'd',
  DAY_SHORT_MONTH: 'd MMM',
  DAY_SHORT_MONTH_YEAR: 'd MMM yyyy',

  SHORT_MONTH: 'MMM',
  SHORT_MONTH_YEAR: 'MMM yyyy',
  SHORT_MONTH_SHORT_YEAR: 'MMM yy',
  MONTH_YEAR: 'MMMM yyyy',

  DATE_TIME: 'dd-MM-yyyy HH:mm:ss',

  DATE_TIME_TIDY: 'dd/MM/yyyy, HH:mm',

  QUARTER_SHORT_YEAR: 'QQQ yy',

  YEAR: 'yyyy',

  LOCALIZED: 'P',

  ISO_DATE: 'yyyy-MM-dd',
} as const;

export const DATE_TIME_FORMATS = {
  LOCALIZED: 'Pp',
} as const;
