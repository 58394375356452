import { forwardRef, useMemo, useState } from 'react';
import { Button, Checkbox, type SelectGroup, type SelectItem, useBaseSelectContext } from '~/components/granular';
import { BackButton } from '../components/back-button';
import { Loading } from '../components/loading';
import { NoData } from '../components/no-data';
import { SearchInput } from '../components/search-input';
import { SelectOption } from '../components/select-option';
import { VirtualisedSelectList } from '../components/virtualised-select-list';
import { DropdownSelect } from '../dropdown-select/DropdownSelect';
import type { GroupedSelectProps } from './GroupedSelect.props';

export const GroupedSelect = forwardRef<HTMLDivElement, GroupedSelectProps>(
  (
    {
      appearance = 'filter',
      size = 'small',
      applyLabel,
      backLabel,
      disableFiltering,
      disabled,
      indeterminateItems,
      items = [],
      label,
      leftIcon,
      loading,
      noDataLabel,
      placeholder,
      searchPlaceholder,
      selectedItems = [],
      selectedLimit,
      trigger,
      width,
      onApplySelection,
      onGroupSelection,
    },
    forwardedRef,
  ) => {
    return (
      <DropdownSelect
        ref={forwardedRef}
        appearance={appearance}
        applyLabel={applyLabel}
        backLabel={backLabel}
        disableFiltering={disableFiltering}
        disabled={disabled}
        indeterminateItems={indeterminateItems}
        items={items}
        label={label}
        leftIcon={leftIcon}
        loading={loading}
        noDataLabel={noDataLabel}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        selectedLimit={selectedLimit}
        size={size}
        trigger={trigger}
        width={width}
      >
        <Groups onApplySelection={onApplySelection} onGroupSelection={onGroupSelection} />
      </DropdownSelect>
    );
  },
);

function Groups({
  onApplySelection,
  onGroupSelection,
}: { onApplySelection: (group: SelectGroup) => void; onGroupSelection?: (group: SelectGroup | null) => void }) {
  const {
    applyLabel,
    indeterminateItems,
    items,
    loading,
    searchTerm,
    selectedItems,
    selectedLimit,
    handleSearchChange,
    resetSearchTerm,
    toggleOpen,
  } = useBaseSelectContext();

  const [activeGroup, setActiveGroup] = useState<SelectGroup | null>(null);
  const [tempChildSelection, setTempChildSelection] = useState<Array<SelectItem>>(selectedItems);

  function handleGroupClick(item: SelectItem | SelectGroup) {
    if ('children' in item) {
      handleSearchChange();
      setActiveGroup(item);
      onGroupSelection?.(item);
    }
  }

  function resetState() {
    handleSearchChange();
    setTempChildSelection([]);
    setActiveGroup(null);
    onGroupSelection?.(null);
  }

  function handleTempChildSelection(item: SelectItem | SelectGroup) {
    if (tempChildSelection.includes(item)) {
      setTempChildSelection((prev) => prev.filter((prevItem) => prevItem !== item));
    } else {
      setTempChildSelection((prev) => [...prev, item]);
    }
  }

  function handleSelectAll() {
    if (tempChildSelection.length < childItems.length) {
      setTempChildSelection(childItems);
    } else {
      setTempChildSelection([]);
    }
  }

  function handleApplySelection() {
    if (activeGroup && tempChildSelection.length) {
      onApplySelection({ ...activeGroup, children: tempChildSelection });
    }

    resetState();
    resetSearchTerm();
    toggleOpen?.(false);
  }

  const childItems = useMemo(() => {
    if (!activeGroup) {
      return [];
    }

    const group = items.find((item) => item.value === activeGroup.value);
    if (group && 'children' in group) {
      return group.children;
    }

    return [];
  }, [items, activeGroup]);

  const filteredChildItems = useMemo(() => {
    if (!activeGroup) {
      return [];
    }
    const group = items.find((item) => item.value === activeGroup.value);

    if (group && 'children' in group) {
      return group.children.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return [];
  }, [items, activeGroup, searchTerm]);

  const isItemSelected = (item: SelectItem) => {
    if (tempChildSelection.includes(item)) {
      return true;
    }
    if (indeterminateItems?.some((i) => i.value === item.value)) {
      return 'indeterminate';
    }
    return false;
  };

  const isItemSelectedLimitReached = useMemo(() => {
    const selectedItemsCount = tempChildSelection.length;
    return selectedLimit !== undefined && selectedItemsCount >= selectedLimit;
  }, [selectedLimit, tempChildSelection]);

  const displaySelectAll = useMemo(
    () => (!!activeGroup && !selectedLimit) || (!!activeGroup && !!selectedLimit && childItems.length <= selectedLimit),
    [activeGroup, selectedLimit, childItems],
  );

  return (
    <>
      <BackButton visible={!!activeGroup} onClick={resetState} />
      <SearchInput
        withSelectAll={displaySelectAll}
        onSelectAll={handleSelectAll}
        isChecked={!!tempChildSelection.length && tempChildSelection.length === childItems.length}
        itemsCount={childItems.length}
      />

      {loading && <Loading />}

      {!loading && !items.length && !filteredChildItems.length && <NoData />}

      {!loading && !activeGroup ? (
        <VirtualisedSelectList>
          {({ data, style, index }) => (
            <SelectOption style={style} item={data[index]} onItemClick={handleGroupClick}>
              {data[index].label}
            </SelectOption>
          )}
        </VirtualisedSelectList>
      ) : (
        <VirtualisedSelectList items={filteredChildItems}>
          {({ data, style, index }) => (
            <SelectOption
              style={style}
              item={data[index]}
              isSelected={isItemSelected(data[index])}
              onItemClick={handleTempChildSelection}
              disabled={isItemSelectedLimitReached && !isItemSelected(data[index])}
            >
              <Checkbox
                id={data[index].value}
                className="overflow-hidden whitespace-nowrap"
                name={data[index].value}
                checked={isItemSelected(data[index])}
                label={data[index].label}
                disabled={isItemSelectedLimitReached && !isItemSelected(data[index])}
              />
            </SelectOption>
          )}
        </VirtualisedSelectList>
      )}

      {activeGroup && (
        <div className="flex border-neutral-200 border-t px-4 py-3">
          <Button size="small" className="!justify-center flex-grow" onClick={handleApplySelection}>
            {applyLabel}
          </Button>
        </div>
      )}
    </>
  );
}
