import { Transition } from '@headlessui/react';
import { Children, cloneElement, isValidElement, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { contextFactory } from '~/utils/contextFactory';
import { classNames } from '~/utils/styles';

import { Icon, LogoOrIcon } from '~/components/granular';

import type { PanelContextProps, PanelFooterProps, PanelHeaderProps, PanelProps } from './Panel.types';

export const [PanelContext, usePanelContext] = contextFactory<PanelContextProps>();

export function Panel({ children, collapseOnResize = true, collapsedWidth = 1000 }: PanelProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!collapseOnResize) {
      return;
    }

    if (width < collapsedWidth) {
      setIsCollapsed(true);
    }
  }, [width, collapsedWidth, collapseOnResize]);

  function handleToggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <PanelContext.Provider value={{ isCollapsed, onToggleCollapse: handleToggleCollapse }}>
      <div
        className={classNames(
          'flex h-screen flex-col border-neutral-300 border-r bg-neutral-100 transition-width duration-300 ease-in-out',
          {
            'w-[200px]': !isCollapsed,
            'w-[56px]': isCollapsed,
          },
        )}
        role="complementary"
      >
        {Children.map(children, (child) => {
          if (isValidElement<{ isCollapsed: boolean }>(child)) {
            return cloneElement(child, { isCollapsed });
          }
          return child;
        })}
      </div>
    </PanelContext.Provider>
  );
}

export function PanelHeader({ title, subtitle, logo }: PanelHeaderProps) {
  const { isCollapsed } = usePanelContext();

  return (
    <div className={classNames('flex items-center gap-2 px-3 pt-[14px] text-neutral-1000')}>
      <div>
        <LogoOrIcon logo={logo} isCollapsed={isCollapsed} />
      </div>

      <Transition
        className="overflow-hidden whitespace-nowrap"
        show={!isCollapsed}
        enter="transform transition-opacity duration-300"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transform duration-300 transition-opacity ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="overflow-hidden text-ellipsis font-semibold text-base leading-5">{title}</div>
        <div className="overflow-hidden text-ellipsis font-normal text-3xs leading-3">{subtitle}</div>
      </Transition>
    </div>
  );
}

export function PanelBody({ children }: PanelFooterProps) {
  return <div className="overflow-hidden px-3 pt-8">{children}</div>;
}

export function PanelFooter({ children }: PanelFooterProps) {
  const { isCollapsed, onToggleCollapse } = usePanelContext();
  return (
    <div className="flex flex-grow-0 p-4">
      <Transition
        className="overflow-hidden whitespace-nowrap"
        show={!isCollapsed}
        enter="transform transition-opacity duration-300"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transform duration-300 transition-opacity ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
      <div className="ml-auto">
        <button
          type="button"
          className="flex h-6 w-6 items-center justify-center border border-neutral-300 bg-white text-neutral-700 hover:shadow-sm"
          onClick={onToggleCollapse}
          data-state={isCollapsed ? 'closed' : 'open'}
        >
          <Icon icon={isCollapsed ? 'MenuUnfoldIcon' : 'MenuFoldIcon'} width={16} height={16} />
        </button>
      </div>
    </div>
  );
}
